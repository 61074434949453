<template>
  <div>
    <div v-if="showBlock === 'screen_1'">
      <zee-toast v-if="toastData.show" :type="toastData.type" :message="toastData.message" :show="toastData.show"
        :time="10000" @clearToast="clearToast" />
      <zee-container :title="'Program Plan - Upcoming Weeks'" :route="'/solutions/zeetv/select-acds'">
        <details open>
          <summary class="font-weight-bold fs-4 ml-2">Summary</summary>
          <zee-program :generatePlanTableHeader="generateProgramTableHeader"
            :generatePlanTableBody="generateProgramTableBody" />
        </details>
        <div class="d-flex justify-content-center mt-5">
          <zee-button :title="'Add more program launches'" @onClick="handleOneMoreProgram"
            class="zee-add-plan-button" />
          <zee-button :title="'Generate Launch Plan'" @onClick="handleButtonClick" class="zee-generate-plan-button" />

        </div>
        <div v-if="showGeneratedPlan" class="d-flex mt-5 justify-content-center">
          <zee-table :hideFirst="true" :changeColor="true" :tableBodyData="generateProgramResultTableFixedBody"
            :tableHeaderData="generateProgramResultTableFixedHeader"  />
          <zee-table :tableHeaderData="generateProgramResultTableHeader"
            :tableBodyData="generateProgramResultTableBody" />
        </div>
        <div class="d-flex justify-content-end mt-5">
          <zee-button @onClick="handleNext" :title="'Next'" :isDisable="!showGeneratedPlan" />
        </div>
      </zee-container>
      <page-loader v-if="isLoading" />
    </div>
    <div v-if="showBlock === 'screen_2'">
      <zee-toast v-if="toastData.show" :type="toastData.type" :message="toastData.message" :show="toastData.show"
        :time="3000" @clearToast="clearToast" />
      <zee-container :route="'/solutions/zeetv/program-plans'" :showIcon="true">
        <div class="zee-table-card-background-1" v-show="!show_add_program_screen">
          <div>
            <details open>
              <summary class="font-weight-bold fs-4 ml-2">Summary</summary>
              <zee-table :isProgramTable="true" :tableHeaderData="addProgramTableHeader"
                :tableBodyData="tableProgramBody" />
              <zee-table :isProgramTable="true" :tableBodyData="tableBodyWeeklyData" />
              <div class="d-none">
                <zee-table :isProgramTable="true" :tableBodyData="toShowStartDate" />
                <zee-table :isProgramTable="true" :tableBodyData="toShowEndDate" />
              </div>
              <zee-table :isProgramTable="true" :bgTransparentOnEmpty="true"
                :tableBodyData="secondagesPercantageSummaryTable" />
              <div class="d-none">
                <zee-table :isProgramTable="true" :tableBodyData="secondagesSummaryTable" />
              </div>
            </details>
          </div>
          <!-- <zee-custom-table :customInputData="customDataForNetwork" />
          {{ customDataForNetwork }} -->
        </div>

        <div class="d-flex mt-5">
          <zee-button :title="'Add New Programs'" @onClick="handleAddPrograms" class="zee-add-plan-button" />
          <ul class="fs-5 font-weight-bold ">
            <div class="fs-6 font-weight-bold">
              <h3>Please Note:</h3>
            </div>

            <li class="mt-2">maximum secondages that can be allocated is :
              shortest acds
              available * 1.7% <br class="mt-3" /> for eg. for 10 seconders
              max possible secondages are 17%, <br class="mt-3" />for 5 seconders it is 8%
            </li>

            <li class="mt-2">Sum of shortest ACD's of all selected has
              to be
              at least 60</li>

            <li class="mt-2">Enter atleast 4 programs
            </li>
            <li class="mt-2">Consumed Secondages must be minimum 100% and not exceeds 139%</li>
            <li class="mt-2">A part of the 14280 seconds/week is allocated for network channels by default for
              everyweek
            </li>
          </ul>
        </div>

        <!-- <h1>{{ tableBodyWidth }}</h1> -->



        <div class="mt-5 zee-table-card-background-1">
          <!-- <zee-custom-table
            :input="true"
            :customInputData="addProgramCustomInputTableFixed"
            @onInput="handleNewProgramInput1"
          /> -->



          <details open>
            <summary class="font-weight-bold fs-4 ml-2">New Program (%)</summary>

            <zee-custom-table :input="true" :customInputData="addProgramCustomInputTable"
              :customInputHeaderData="addProgramTableHeader"
              @onInput="handleNewProgramInput" @onSelect="handleDropdownSelect" @getSelectedOptions="getSelectedOptions"
              @removeItem="removeNewProgram" />
          </details>
        </div>
        <div>
          <!-- <zee-table :input="true" :tableBodyData="newWeeklyTargetGRPHeader" /> -->
          <div class="zee-new-program-summary-table">
            <div>
              <!-- <h5 class="font-weight-bold ml-3">Min. Available Secondages</h5> -->
              <zee-table :isProgramTable="true" :bgTransparentOnEmpty="true"
                :tableBodyData="newProgramOutputResultTableTEST" :isSecondagesTable="true" />
              <zee-table :isProgramTable="true" :bgTransparentOnEmpty="true"
                :tableBodyData="newProgramOutputRemainingSecondagesTableTEST" :isSecondagesTable="true" />
              <zee-table :isProgramTable="true" :bgTransparentOnEmpty="true"
                :tableBodyData="newProgramOutputTotalSecondagesTableTEST" v-if="showSecondages" :isSecondagesTable="true" />
              <!-- :tableHeaderData="generateProgramResultTableHeader" -->
            </div>
          </div>
        </div>

        <div class="fs-3 font-weight-bold d-flex justify-content-center text-danger" v-if="showWarningMessage">
          Consumed Secondages exceeds 139%, it has to be maximum 139%
        </div>
        <!-- <div class="fs-5 font-weight-bold mt-5">
          *A part of the 14280 seconds/week is allocated for network channels by default for
          everyweek
        </div> -->
        <div class="d-flex justify-content-between mt-5">
          <zee-button @onClick="goBackInPlanning" :title="'Back'" />
          <zee-button style="width: 20%; background-color: black" @onClick="calculateMinWeeks(false)"
            :title="'Calculate Remaining Secondages'" v-if="addProgramCustomInputTable.length !== 0" />

          <div v-if="showSave" class="d-flex justify-content-end">
            <zee-input :placeholder="'Enter Plan Name'" @onChange="getProgramName" />
            <zee-button @onClick="newProgramOutput" :title="'save'" :isDisable="isDisable" />
          </div>
        </div>
        <!-- <div
          class="d-flex justify-content-end fs-5 font-weight-bold d-flex justify-content-end"
        >
          Click here to see Min. Available Secondages
        </div> -->
        <page-loader v-if="isLoading" />
      </zee-container>
    </div>

    <program-percentage-modal :modalShow="showProgramPercentageModal"
      @closeModal="showProgramPercentageModal = false" />
  </div>
</template>

<script>
import {
  Container,
  Program,
  Button,
  Table,
  TEMP_CustomInputTable,
  Input,
  ProgramPercentageInfoModal,
} from "../components";
import { zeeTvSolutions } from "../services/zeeTvSolutions";
const zeeSolutions = new zeeTvSolutions();
import Toast from "@/components/Toast/Toast.vue";

import PageLoader from "@/widgets/PageLoader.vue";

export default {
  components: {
    "zee-program": Program,
    "zee-button": Button,
    "zee-table": Table,
    "zee-custom-table": TEMP_CustomInputTable,
    "page-loader": PageLoader,
    "zee-container": Container,
    "zee-toast": Toast,
    "program-percentage-modal": ProgramPercentageInfoModal,
    "zee-input": Input,
  },
  data() {
    return {
      isFromOutputTable: false,
      showWarningMessage: false,
      show_add_program_screen: "",
      userEnteredPlanName: "",
      toastData: {
        show: false,
        type: "",
        message: "",
      },
      secondagesSummaryTable: [{ columns: [] }],
      secondagesPercantageSummaryTable: [{ columns: [] }],
      tableBodyWeeklyData: [
        {
          columns: [],
        },
      ],
      toShowStartDate: [
        {
          columns: [],
        },
      ],
      toShowEndDate: [
        {
          columns: [],
        },
      ],
      tableProgramBody: [{ columns: [] }],
      newProgramOutputResultTableTEST: [{ columns: [] }],
      newProgramOutputTotalSecondagesTableTEST: [{ columns: [] }],
      newProgramOutputRemainingSecondagesTableTEST: [{ columns: [] }],
      addProgramCustomInputTableFixed: [
        {
          columns: [
            { id: [0, 0], name: "" },
            { id: [0, 2], name: "" },
            { id: [0, 3], name: "" },
          ],
        },
      ],
      newWeeklyTargetGRPHeader: [
        {
          columns: [],
        },
      ],
      addProgramCustomInputTable: [],
      newProgramOutputResultTable: [
        {
          columns: [],
        },
      ],
      addProgramTableBody: [
        { columns: [{ name: "" }, { name: "" }, { name: "" }] },
        {
          columns: [
            { name: "", show: true },
            { name: "", show: true },
            { name: "Min. Available Seconds", color: true },
          ],
        },
      ],
      addProgramTableBodyRight: [
        {
          columns: [{ name: "" }, { name: "" }, { name: "" }, { name: "" }, { name: "" }],
        },
        {
          columns: [{ name: "" }, { name: "" }, { name: "" }, { name: "" }, { name: "" }],
        },
      ],
      addProgramTableHeaderFixed: [
        {
          columns: [
            { name: "Program Name", expand: true },
            { name: "Program Genre" },
            { name: "Available ACDs" },
            { name: "Program Name", expand: true },
            { name: "Program Genre" },
            { name: "Available ACDs" },
            { name: "Available ACDs" },
          ],
        },
      ],
      addProgramTableHeader: [
        {
          columns: [
            { name: "Program Name" },
            { name: "Program Genre" },
            { name: "Available ACDs" },
            { name: "GRP" },
          ],
        },
      ],
      addProgramTableHeaderCustom: [
        {
          columns: [],
        },
      ],
      generateProgramTableHeader: [
        {
          columns: [
            { name: "Program Name" },
            { name: "Date of Launch" },
            { name: "Program Genre" },
            { name: "Priority" },
            { name: "Target GRPs" },
            { name: "Promotion Duration/week" },
            { name: "Available ACDs" },
            { name: "Prog Start Time" },
            { name: "Prog Frequecy" },
          ],
        },
      ],
      generateProgramTableBody: [
        {
          columns: [
            {
              id: "0",
              name: "Dance India Dance",
            },
            {
              id: "1",
              name: "Reality Show",
            },
            {
              id: "2",
              name: "5, 10, 15",
            },
            {
              id: "3",
              name: "100-500",
            },
            {
              id: "4",
              name: "1000",
            },
            {
              id: "5",
              name: "3000",
            },
            {
              id: "6",
              name: "5000",
            },
            {
              id: "7",
              name: "8000",
            },
            {
              id: "8",
              name: "8000",
            },
          ],
        },
      ],
      generateProgramResultTableFixedHeader: [
        {
          columns: [{ name: "Program Name" }, { name: "Summary" }],
        },
      ],
      generateProgramResultTableFixedBody: [
        {
          columns: [
            { name: "Dance India Dance" },
            { name: "Estimated GRPs", color: true },
          ],
        },
        {
          columns: [
            { name: "Dance India Dance", show: true },
            { name: "Total Secondages", color: true, width: true },
          ],
        },

        {
          columns: [
            { name: "Dance India Dance", show: true },
            { name: "Weekly Targets", color: true },
          ],
        },
        {
          columns: [
            { name: "Dance India Dance", show: true },
            { name: "Min. Available Secondages", color: true },
          ],
        },
      ],
      generateProgramResultTableHeader: [
        {
          columns: [
            { name: "Week 4" },
            { name: "Week 3" },
            { name: "Week 2" },
            { name: "Week 1" },
          ],
        },
      ],
      generateProgramResultTableBody: [
        {
          columns: [{ name: "" }, { name: "" }, { name: "" }, { name: "" }],
        },
        {
          columns: [{ name: "" }, { name: "" }, { name: "" }, { name: "" }],
        },
        {
          columns: [{ name: "" }, { name: "" }, { name: "" }, { name: "" }],
        },
        {
          columns: [{ name: "" }, { name: "" }, { name: "" }, { name: "" }],
        },
      ],
      programs: [],
      addProgramCount: 0,
      isLoading: false,
      showBlock: "screen_1",
      data: [],
      isDisable: true,
      showGeneratedPlan: false,
      remainingWeeklySeconds: [13020, 13020],
      totalWeeklySecondages: [],
      programSum: [],
      programForDrodpown: [],
      weeklyTotalSeconds: [],
      showSave: false,
      tableBodyWidth: "",
      customDataForNetwork: [
        {
          columns: [
            // { id: [0, 0], name: "Network" },
            // { id: [0, 2], name: "Network" },
            // { id: [0, 3], name: "" },
          ],
        },
      ],

      showProgramPercentageModal: false,
      showSecondages: false,
      programGenreForAssignment: []
    };
  },
  methods: {
    removeNewProgram(payload) {
      let programs = this.addProgramCustomInputTable.filter((e, i) => payload.index !== i);
      this.addProgramCustomInputTable = [];
      for (let i = 0; i < programs.length; i++) {
        this.addProgramCustomInputTable.push(programs[i]);
      }
      this.calculateMinWeeks(true);
    },
    getProgramName(e) {
      this.userEnteredPlanName = e;
      this.isDisable = false;

      sessionStorage.setItem("userEnteredPlanName", e);
    },
    getSelectedOptions(data) {
      let acds = data.e.map((item) => {
        return item.key.replace(" sec", "");
      });
      for (
        let i = 3;
        i < this.addProgramCustomInputTable[data.index].columns.length;
        i++
      ) {
        if (acds.length > 0) {
          this.addProgramCustomInputTable[data.index].columns[i].isDisable = false;
        } else {
          this.addProgramCustomInputTable[data.index].columns[i].isDisable = true;
        }
      }
      this.addProgramCustomInputTable[data.index].columns[2].value = acds.join(",");
    },
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    handleOneMoreProgram() {
      this.toastData = {
        show: true,
        type: "info",
        message: "This feature is being built",
      };
    },
    goBackInPlanning() {
      if (this.show_add_program_screen) {
        this.$router.push(`/solutions/zeetv/program-launch-details`);
        return;
      }
      this.showBlock = "screen_1";
    },
    // handleBackButton(e) {
    //   console.log(e);
    //   // switch(e) {
    //   //   case :
    //   // }
    // },
    // calculateWidth() {
    //   this.tableBodyWidth = document.getElementById("parent_table").clientWidth;
    //   console.log(this.tableBodyWidth);
    // },
    handleDropdownSelect(e) {
      let id = e.id;

      this.addProgramCustomInputTable[id[0]].columns[id[1]].value = e.event;


      // console.log(this.programForDrodpown.filter((x) => x.name === "SWARNA SWAR BHARAT"));
      console.log(this.programGenreForAssignment)
      if(this.programGenreForAssignment.length > 0) {
          let x = this.programGenreForAssignment.filter((y) => y.name === e.event.text);

          this.addProgramCustomInputTable[id[0]].columns[1].name = x[0].genre.split("/")[0];
      }
      // console.log(x);
      // console.log(x[0].genre);

      // console.log((this.addProgramCustomInputTable[0].columns[1].name = e.event.text));
    },
    handleButtonClick() {
      this.GETgeneratePlan();
    },
    getAddPlanSummary() {
      let runId = sessionStorage.getItem("run_id");
      let weeks = +sessionStorage.getItem("weeks");
      zeeSolutions.getAddplanSummary(runId).then((res) => {
        this.tableProgramBody[0].columns = [];
        this.customDataForNetwork[0].columns = [];
        this.toShowStartDate[0].columns = [];
        this.toShowEndDate[0].columns = [];
        this.secondagesSummaryTable[0].columns = [];
        this.secondagesPercantageSummaryTable[0].columns = [];

        let objects = [];
        // let networkObject = [];

        let fields = [res[0], res[1], res[2].toString()];

        // let someData = {
        //   columns: [
        //     {

        //     }
        //   ]
        // }

        for (let xc = 0; xc < weeks + 3; xc++) {
          this.customDataForNetwork[0].columns.push({
            id: [xc, xc],
            name: "",
            value: "",
          });
        }

        let tem_1 = [];
        let temp_2 = [];
        let temp_3 = [];
        let temp_4 = [];

        for (let x = 0; x < 3; x++) {
          objects.push({ name: fields[x] });
        }
        let field3 = ["", ""];
        let field4 = ["", ""];

        for (let x = 0; x < 2; x++) {
          tem_1.push({ name: field3[x], show: true });
        }
        for (let x1 = 0; x1 < 2; x1++) {
          temp_2.push({ name: field4[x1], show: true });
          temp_3.push({ name: field3[x1], show: true });
          temp_4.push({ name: field3[x1], show: true });
        }
        this.tableProgramBody[0].columns.push(...objects);

        this.toShowStartDate[0].columns.push(...tem_1);
        this.toShowEndDate[0].columns.push(...temp_2);
        this.secondagesSummaryTable[0].columns.push(...temp_3);
        this.secondagesPercantageSummaryTable[0].columns.push(...temp_3);

        this.secondagesSummaryTable[0].columns.push({
          name: "Minimum Available Secondages",
          color: true,
        });

        this.secondagesPercantageSummaryTable[0].columns.push({
          name: "Consumed Secondages (%)",
          color: true,
        });

        this.toShowStartDate[0].columns.push({ name: "Start Date", color: true });
        this.toShowEndDate[0].columns.push({ name: "End Date", color: true });

        // console.log(res[4]);
        let runId = sessionStorage.getItem("run_id");

        zeeSolutions.getAddplanSummary(runId).then((res) => {
          for (var i = 0; i < res[3].length; i++) {
            // console.log(res[3][i]);
            this.secondagesSummaryTable[0].columns.push({ name: res[3][i], blue: true });
            this.secondagesPercantageSummaryTable[0].columns.push({
              name: Math.round((res[3][i] * 100) / 14280),
              blue: true,
            });
          }
        });

        for (var i = weeks - 1; i >= 0; i--) {
          this.tableProgramBody[0].columns.push({ name: Math.round(res[3][i]) });
          this.toShowStartDate[0].columns.push({
            name: res[4][i.toString()].start,
          });
          this.toShowEndDate[0].columns.push({
            name: res[4][i.toString()].end,
          });
        }
        // console.log("in the api call", this.toShowStartDate[0].columns)
        this.TEMP_handleTableCreation(this.toShowStartDate, this.toShowEndDate);
      });
    },
    sumofAcdsValues() {
      let sumAcdsValue = 0;
      for (let i = 0; i < this.addProgramCustomInputTable.length; i++) {
        let maxAcdsValue = 0;
        let acdsValues = this.addProgramCustomInputTable[i].columns[2].value.split(",");
        for (let max = 0; max < acdsValues.length; max++) {
          if (parseInt(acdsValues[max]) > maxAcdsValue)
            maxAcdsValue = parseInt(acdsValues[max]);
        }
        for (let max = 0; max < acdsValues.length; max++) {
          if (parseInt(acdsValues[max]) < maxAcdsValue)
            maxAcdsValue = parseInt(acdsValues[max]);
        }
        sumAcdsValue += maxAcdsValue;
      }

      return sumAcdsValue;
    },
    validateInputValue() {
      for (var t = 0; t < this.addProgramCustomInputTable.length; t++) {
        for (var l = 0; l < this.addProgramCustomInputTable[t].columns.length; l++) {
          // console.log(this.addProgramCustomInputTable[t].columns[l].value)
          if (l != 1 && this.addProgramCustomInputTable[t].columns[l].value == '') return false;
        }
      }
      return true;
    },
    calculateMinWeeks(prompt) {
      if (!prompt && !this.validateInputValue()) {
        this.toastData = {
          show: true,
          type: "info",
          message: "All fields are required",
        };
        return;
      }
      this.showSecondages = true
      // let table_data = this.addProgramCustomInputTable;
      // if (table_data.length > 0) {
      //   let last_elem = table_data.slice(-1)[0].columns[0].value.text;
      //   let options = table_data.slice(-1)[0].columns[0].options;

      //   this.getProgramData(options, last_elem);
      // }
      this.newProgramOutputResultTableTEST[0].columns = [];
      this.newProgramOutputTotalSecondagesTableTEST[0].columns = [];
      this.newProgramOutputRemainingSecondagesTableTEST[0].columns = [];

      for (let i = 0; i < this.weeklyTotalSeconds.length; i++) {
        this.newProgramOutputResultTableTEST[0].columns.push({
          name: Math.round(this.weeklyTotalSeconds[i] + 8),
          blue: true,
        });
        this.newProgramOutputRemainingSecondagesTableTEST[0].columns.push({
          name: 100 - Math.round(this.weeklyTotalSeconds[i] + 8),
          blue: true,
        });
      }

      for (let i = 0; i < this.totalWeeklySecondages.length; i++) {
        this.newProgramOutputTotalSecondagesTableTEST[0].columns.push({
          blue: true,
          name: this.totalWeeklySecondages[i],
        });
      }

      // for(let i = 0; i < this.totalWeeklySecondages.length; i++) {
      //   this.newProgramOutputTotalSecondagesTableTEST[0].columns.push({name: this.totalWeeklySecondages[i], blue: true})
      // }
      let output = [];
      // this.newProgramOutputResultTableTEST[0].columns = [];

      // this.newProgramOutputResultTableTEST[0].columns.push({
      //   name: "Estimated Secondages",
      // });
      // let sumArray = [];
      console.log(this.addProgramCustomInputTable, "Console loh");
      for (var t = 0; t < this.addProgramCustomInputTable.length; t++) {
        var o1 = [];
        for (var l = 3; l < this.addProgramCustomInputTable[t].columns.length; l++) {
          // console.log(this.addProgramCustomInputTable[t].columns[l].value);
          o1.push(+this.addProgramCustomInputTable[t].columns[l].value.replace("%", ""));
        }

        output.push(o1);
      }
      console.log(output);
      let arrs = output;
      let weeksTotalArr = [];

      if (prompt && arrs[0] === undefined) {
        this.newProgramOutputResultTableTEST[0].columns = [];
        this.newProgramOutputTotalSecondagesTableTEST[0].columns = [];
        this.newProgramOutputRemainingSecondagesTableTEST[0].columns = [];
      } else if (arrs[0] === undefined) {
        alert("Add program to calculate secondages");
      } else {
        for (let i = 0; i < arrs[0].length; i++) {
          let weektotal = 0;
          for (let j = 0; j < arrs.length; j++) {
            weektotal += arrs[j][i];
          }
          weeksTotalArr.push(weektotal);
        }
      }
      for (let f = 0; f < weeksTotalArr.length; f++) {
        this.newProgramOutputResultTableTEST[0].columns[f].name += weeksTotalArr[f];
        this.newProgramOutputRemainingSecondagesTableTEST[0].columns[f].name =
          100 - this.newProgramOutputResultTableTEST[0].columns[f].name;
        if (this.newProgramOutputResultTableTEST[0].columns[f].name > 100) {
          // this.showWarningMessage = true;
          this.showProgramPercentageModal = true;
        }

        if (this.newProgramOutputResultTableTEST[0].columns[f].name > 139) {
          this.showProgramPercentageModal = true;
          this.showWarningMessage = true;
        } else {
          this.showWarningMessage = false;
        }
        let attempt = true;
        for (var i = 0; i < this.newProgramOutputResultTableTEST[0].columns.length; i++) {
          if(attempt) { 
            this.showSave =
            this.newProgramOutputResultTableTEST[0].columns[i].name >= 100 &&
            this.newProgramOutputResultTableTEST[0].columns[i].name < 140 &&
            this.programInputValidation();

            if(!this.showSave) attempt = false;
          }
        }
        // console.log(isSaveEnabled);

        // isSaveEnabled.length !== 0 ? (this.showSave = true) : (this.showSave = false);

      }
      for (
        let t = 0;
        t < this.newProgramOutputTotalSecondagesTableTEST[0].columns.length;
        t++
      ) {
        // let nameValue = this.newProgramOutputTotalSecondagesTableTEST[0].columns[t].name;
        this.newProgramOutputTotalSecondagesTableTEST[0].columns[t].name = Math.round(
          (14280 * this.newProgramOutputResultTableTEST[0].columns[t].name) / 100
        );
      }
      // name: Math.round(remainingWeeklySeconds[i] - (remainingWeeklySeconds[i] * (parseInt(Math.round(totalSeconds[i]))/100)))
      if (arrs[0] !== undefined) {
        this.newProgramOutputResultTableTEST[0].columns.unshift(
          {},
          {},
          { name: "Consumed Secondages (%)", color: true }
        );
        this.newProgramOutputRemainingSecondagesTableTEST[0].columns.unshift(
          {},
          {},
          { name: "Remaining Secondages (%)", color: true }
        );
        this.newProgramOutputTotalSecondagesTableTEST[0].columns.unshift(
          {},
          {},
          { name: "Total Secondages", color: true }
        );
      }

      // let sum = output.reduce((accumulator, currentValue) => accumulator + currentValue);
      // console.log("this is sum "+sum);
      this.defaultAcdsOptions = [];
    },
    programInputValidation() {
      for (var t = 0; t < this.addProgramCustomInputTable.length; t++) {
        let maxAcdsValue = 0;
        let acdsValues = this.addProgramCustomInputTable[t].columns[2].value.split(",");
        for (let max = 0; max < acdsValues.length; max++) {
          if (parseInt(acdsValues[max]) > maxAcdsValue)
            maxAcdsValue = parseInt(acdsValues[max]);
        }
        for (let max = 0; max < acdsValues.length; max++) {
          if (parseInt(acdsValues[max]) < maxAcdsValue)
            maxAcdsValue = parseInt(acdsValues[max]);
        }
        const percentage = Math.round(((34 * 7 * maxAcdsValue) / 14280) * 100);
        for (var l = 3; l < this.addProgramCustomInputTable[t].columns.length; l++) {
          if (parseInt(this.addProgramCustomInputTable[t].columns[l].value) > percentage)
            return false;
        }
      }
      return true;
    },
    TEMP_handleTableCreation(startDateArray, endDateArray) {
      let weeks = +sessionStorage.getItem("weeks");
      this.generateProgramResultTableHeader[0].columns = [];
      this.addProgramTableHeader[0].columns = [];
      this.addProgramTableHeaderCustom[0].columns = [];
      this.newWeeklyTargetGRPHeader[0].columns = [];

      const objects = [];
      const objects1 = [];

      let fields = ["Program Name", "Program Genre", "Available ACDs"];

      let fields1 = ["Program Name", "Program Genre", "Available ACDs"];

      for (let x = 0; x < 3; x++) {
        objects.push({ name: fields[x] });
      }
      for (let y = 0; y < 3; y++) {
        objects1.push({ name: fields1[y] });
      }
      // console.log(objects);

      this.addProgramTableHeader[0].columns.push(...objects);
      this.addProgramTableHeaderCustom[0].columns.push(...objects1);

      let startDates = startDateArray[0].columns.slice(3);
      let endDates = endDateArray[0].columns.slice(3);
      let j = 0;
      for (var i = weeks; i > 0; i--) {
        this.newWeeklyTargetGRPHeader[0].columns.push({ name: `week ${i}` });
        this.addProgramTableHeader[0].columns.push({
          name: `week ${i}`,
          startDate: startDates[j],
          endDate: endDates[j],
        });
        this.addProgramTableHeaderCustom[0].columns.push({ name: `week ${i}` });

        this.generateProgramResultTableHeader[0].columns.push({
          name: `week ${i}`,
        });
        j++;
      }
    },

    TEMP_handleTopTableBodyData() {
      // console.log(sessionStorage.getItem("run_id"));

      let prevData = JSON.parse(localStorage.getItem("programDetails"));

      this.generateProgramResultTableFixedBody[0].columns[0].name =
        prevData[0].programName;

      this.generateProgramTableBody[0].columns[0].name = prevData[0].programName;
      this.generateProgramTableBody[0].columns[1].name = prevData[0].programLaunchDate;
      this.generateProgramTableBody[0].columns[2].name = prevData[0].programGenre;
      this.generateProgramTableBody[0].columns[3].name = "1";
      this.generateProgramTableBody[0].columns[4].name = `${prevData[0].programTargetGRPs.min} - ${prevData[0].programTargetGRPs.max}`;
      this.generateProgramTableBody[0].columns[5].name = `${prevData[0].programNumberOfWeeks} week`;
      this.generateProgramTableBody[0].columns[6].name = Object.keys(
        JSON.parse(sessionStorage.getItem("acds"))[0]
      ).toString();
      this.generateProgramTableBody[0].columns[7].name =
        prevData[0].programRuntime.startTime;

      this.generateProgramTableBody[0].columns[8].name = prevData[0].programFrequency.toString();
    },

    handleAddPrograms() {
      for(let p = 0; p < this.addProgramCustomInputTable.length; p++) {
        for(let v = 0; v < this.addProgramCustomInputTable[p].columns.length; v++) {
          if(v !=1 && this.addProgramCustomInputTable[p].columns[v].value == "") {
            this.toastData = {
              show: true,
              type: "info",
              message: "All fields are required",
            };
            return;
          }
        }
      }
      
      this.acdsOptions = this.defaultAcdsOptions;
      this.isDisable = false;

      let table_data = this.addProgramCustomInputTable;
      // console.log("table_data", table_data)
      if (table_data.length > 0) {
        let last_elem = table_data.slice(-1)[0].columns[0].value.text;
        let options = table_data.slice(-1)[0].columns[0].options;

        // console.log("last_elem", last_elem)

        let x = options.filter((y) => y.text !== last_elem)
        this.programForDrodpown = x
      }

      let defaultAcdsOptions = [
        {
          id: "5",
          name: "5 sec",
          value: false,
          $isDisabled: false,
          key: "5 sec",
        },
        {
          id: "10",
          name: "10 sec",
          value: false,
          $isDisabled: false,
          key: "10 sec",
        },
        {
          id: "15",
          name: "15 sec",
          value: false,
          $isDisabled: false,
          key: "15 sec",
        },
        {
          id: "20",
          name: "20 sec",
          value: false,
          $isDisabled: false,
          key: "20 sec",
        },
        {
          id: "25",
          name: "25 sec",
          value: false,
          $isDisabled: false,
          key: "25 sec",
        },
        {
          id: "30",
          name: "30 sec",
          value: false,
          $isDisabled: false,
          key: "30 sec",
        },
        {
          id: "40",
          name: "40 sec",
          value: false,
          $isDisabled: false,
          key: "40 sec",
        },
        {
          id: "50",
          name: "50 sec",
          value: false,
          $isDisabled: false,
          key: "50 sec",
        },
        {
          id: "60",
          name: "60 sec",
          value: false,
          $isDisabled: false,
          key: "60 sec",
        },
      ]
      let weeks = +sessionStorage.getItem("weeks");
      let apctd = {
        columns: [
          {
            id: [0, 0],
            name: "Program Name",
            value: "",
            type: "dropdown",
            options: this.programForDrodpown,
            // selectedOption: { id: "0", text: "TMKOC" },
          },
          { id: [0, 1], name: "", value: "" },
          {
            id: [0, 2],
            name: "Select ACDs",
            type: "dropdown",
            innerType: "multi",
            options: defaultAcdsOptions,
            value: "",
          },
          // { id: [0, 3], name: "", value: "" },
        ],
        index: new Date().getTime()
      };

      let numWeeks = weeks;

      for (var k = 0; k < numWeeks; k++) {
        let toadd = { id: [0, 2 + k + 1], name: "", value: "", isDisable: true };
        // toadd.id[1] = ;
        apctd.columns.push(toadd);
      }
      for (var i = 1; i <= this.addProgramCustomInputTable.length; i++) {
        for (var j = 0; j < 3 + numWeeks; j++) {
          apctd.columns[j].id = [i, j];
        }
      }
      this.addProgramCustomInputTable.push(apctd);
      // console.log("In the handle new program", this.addProgramCustomInputTable);

    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    handlePlus() {
      this.generatePlan = true;
    },
    handleNext() {
      switch (this.showBlock) {
        case "screen_1":
          this.showBlock = "screen_2";
          break;
        case "screen_2":
          this.$router.push("/solutions/zeetv/program-output");
          break;
        default:
          break;
      }
    },
    GETgeneratePlan() {
      this.isLoading = true;
      zeeSolutions
        .getGeneratedPlan(sessionStorage.getItem("run_id"))
        .then((res) => {
          this.showGeneratedPlan = true;
          this.isDisable = false;

          this.generateProgramResultTableBody[0].columns = [];
          this.generateProgramResultTableBody[1].columns = [];
          this.generateProgramResultTableBody[2].columns = [];
          this.generateProgramResultTableBody[3].columns = [];

          let totalSeconds = res[4].totalSeconds;
          let weeklyTargets = res[1].weeklyTarget;
          let estimatedGrps = res[2].estimatedGrps;
          let remainingWeeklySeconds = res[3].remainingWeeklySeconds;
          this.totalWeeklySecondages = res[3].remainingWeeklySeconds;
          this.weeklyTotalSeconds = res[0].totalSecondsPercentage;
          // this.remainingWeeklySeconds = res[3].remainingWeeklySeconds;
          this.newProgramOutputTotalSecondagesTableTEST[0].columns = [];

          for (let i = 0; i < this.totalWeeklySecondages.length; i++) {
            this.newProgramOutputTotalSecondagesTableTEST[0].columns.push({
              blue: true,
              name: remainingWeeklySeconds[i],
            });
          }
          this.newProgramOutputTotalSecondagesTableTEST[0].columns.unshift(
            {},
            {},
            { name: "Total Secondages", color: true }
          );

          for (var i = estimatedGrps.length - 1; i >= 0; i--) {
            this.generateProgramResultTableBody[0].columns.push({
              name: Math.round(estimatedGrps[i]),
            });
            this.generateProgramResultTableBody[1].columns.push({
              name: Math.round(totalSeconds[i]),
            });
            this.generateProgramResultTableBody[2].columns.push({
              name: weeklyTargets[i],
            });
            this.generateProgramResultTableBody[3].columns.push({
              name: remainingWeeklySeconds[i],
            });
          }

          this.isLoading = false;
        })
        .then(() => {
          this.getAddPlanSummary();
        })

        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
    newProgramOutput() {
      if (this.addProgramCustomInputTable.length < 4) {
        this.toastData = {
          show: true,
          message: "Enter atleast 4 programs",
        }
        this.isDisable = true
        return

      }
      if (this.userEnteredPlanName.length < 1) {
        this.isDisable = true;
        this.toastData = {
          show: true,
          type: "info",
          message: "Enter a plan name",
        };
        return;
      }

      if (!this.programInputValidation()) {
        this.toastData = {
          show: true,
          type: "info",
          message: "Invalid secondages added in week",
        };
        return;
      }
      if (this.sumofAcdsValues() < 60) {
        this.toastData = {
          show: true,
          type: "info",
          message: "Sum of ACDs should atleast be 60",
        };
        return;
      }
      for (var j = 0; j < this.addProgramCustomInputTable.length; j++) {
        let x = this.addProgramCustomInputTable[j].columns
          .map((s) => {
            return s;
          })
          .filter((y) => {
            return y.value !== "";
          });
        // x.length === 0 ? console.log("") : alert("fill all the fields");
        console.log(x);
      }
      let TEMP_P = [];
      for (let q = 0; q < this.addProgramCustomInputTable.length; q++) {
        let arr1 = [];
        for (var p = 3; p < this.addProgramCustomInputTable[q].columns.length; p++) {
          // console.log(this.addProgramCustomInputTable[q].columns[p].value);
          arr1.push(+this.addProgramCustomInputTable[q].columns[p].value.replace("%", ""));
        }
        TEMP_P.push(arr1);
      }
      // console.log(TEMP_P)

      // console.log("TEMP_P", TEMP_P);

      let temp = [];
      for (var i = 0; i < this.addProgramCustomInputTable.length; i++) {
        temp.push({
          programName: this.addProgramCustomInputTable[i].columns[0].value.text,
          programGenre: this.addProgramCustomInputTable[i].columns[1].name,
          availableACDs: this.addProgramCustomInputTable[i].columns[2].value
            .split(",")
            .map(function (item) {
              return parseInt(item, 10);
            }),
          // weeklySecondsTarget: TEMP_P[i].map(item => ((item / 14280) * 100)) ,
          weeklySecondsTarget: TEMP_P[i],
        });
        // console.log("This is temp",temp[i].weeklySecondsTarget)
        // console.log(this.addProgramCustomInputTable[i].columns[i]);
      }

      this.isLoading = true;
      // console.log(sessionStorage.getItem("run_id"))

      // zeeSolutions
      //   .postNewPlans(sessionStorage.getItem("run_id"), temp)
      //   .then((res) => {
      //     sessionStorage.setItem("run_id", res.runid);
      // console.log("res", res);
      // })
      // .catch((err) => {
      //   alert(err);
      // });
      sessionStorage.setItem("newProgramsList", JSON.stringify(this.addProgramCustomInputTable))
      zeeSolutions
        .postNewPlans(sessionStorage.getItem("run_id"), temp)
        .then((res) => {
          sessionStorage.setItem("run_id", res.runid);
          // console.log("res", res);
          zeeSolutions
            .getNewPlanResults(sessionStorage.getItem("run_id"))
            .then(() => {
              // console.log(res);
              // console.log(this.newProgramOutputResultTable[0])
              this.newProgramOutputResultTable[0].columns = [];

              // for (var i = 0; i < +sessionStorage.getItem("weeks"); i++) {
              //   this.newProgramOutputResultTable[0].columns.push({
              //     name: res[0].remainingWeeklySeconds[i],
              //   });
              // }
            })
            .then(() => {
              this.isLoading = false;
              this.$router.push("/solutions/zeetv/program-output");
            })
            .catch((err) => {
              alert(err.runid);
            });
          // console.log(sessionStorage.getItem("run_id"));
        })
        .catch((err) => {
          alert(err.runid);
        });
    },
    handleNewProgramInput(e) {
      this.clearToast();
      let id = e.id;
      this.showSave = false;
      if (e.index > 2) {
        let maxAcdsValue = 0;
        let acdsValues = this.addProgramCustomInputTable[id[0]].columns[2].value.split(
          ","
        );
        for (let max = 0; max < acdsValues.length; max++) {
          if (parseInt(acdsValues[max]) > maxAcdsValue)
            maxAcdsValue = parseInt(acdsValues[max]);
        }
        for (let max = 0; max < acdsValues.length; max++) {
          if (parseInt(acdsValues[max]) < maxAcdsValue)
            maxAcdsValue = parseInt(acdsValues[max]);
        }
        const percentage = Math.round(((34 * 7 * maxAcdsValue) / 14280) * 100);
        if (e.event.target.value > percentage) {
          this.toastData = {
            show: true,
            type: "info",
            message: `Value must be less than to ${percentage}`,
          };
        }
      }

      // let weeks = +sessionStorage.getItem("weeks");
      this.addProgramCustomInputTable[id[0]].columns[id[1]].value;
      this.addProgramCustomInputTable[id[0]].columns[id[1]].value = e.event.target.value;

      console.l;

      let output = [];

      output.push({});

      // let output = [];

      // // for (var j = 3; j < weeks + 3; j++) {
      // for (var i = 0; i < this.addProgramCustomInputTable.length; i++) {
      //   output.push(+this.addProgramCustomInputTable[i].columns[id[1]].value);
      // }

      // output.slice(3, output.length);

      // console.log(output);
      // // }

      // let sum = output.reduce((accumulator, currentValue) => accumulator + currentValue);
      // console.log(sum);

      // console.log(output);

      // console.log(this.addProgramCustomInputTable[id[0]].columns[id[1]].value);
      //check object for empty string
      // const checkObject = this.addProgramCustomInputTable[id[0]].columns.filter((el) => {
      //   return el.value === "";
      // });
      // checkObject.length === 0 ? this.programs.push(this.addProgramCustomInputTable) : console.log("no")
      //  console.log("this.programs",this.programs);
    },
    getProgramData() {
      // console.log("options, last_elem", options, last_elem)
      zeeSolutions
        .getDummyPrograms()
        .then((res) => {
          for (var i = 0; i < res.length; i++) {
            this.programForDrodpown.push({ text: res[i].name, id: i });
          }
          // if (options !== undefined && last_elem !== undefined) {
          //   var data = options.filter((y) => y.text !== last_elem)
          // } else {
          //   console.log("ff")
          // }
          console.log(res, "Response")
          this.programGenreForAssignment = res;
          // this.programForDrodpown = data;
          // return data
        })
        .catch((err) => {
          alert(err);
        });
      // console.log("mm");
    },
    getSummarySecondages() {
      this.secondagesSummaryTable[0].columns = [];
      this.secondagesPercantageSummaryTable[0].columns = [];
    },
  },
  created() {
    // let y = sessionStorage.getItem("showFromProgramOutput")
    // console.log(y === "true")
    // y === "true" ? ((this.showBlock = "screen_2"), this.GETgeneratePlan())
    //   : (this.showBlock = "screen_1");
    let x = sessionStorage.getItem("show_add_program_screen");
    this.show_add_program_screen = x === "true" ? true : false;
    console.log(this.show_add_program_screen);
    // console.log(typeof x);
    x === "true"
      ? ((this.showBlock = "screen_2"), this.GETgeneratePlan())
      : (this.showBlock = "screen_1");

    let y = sessionStorage.getItem("backFromOutputs")
    if (y !== null) {
      // console.log(y)
      y === "true" ? this.isFromOutputTable = true : this.isFromOutputTable = false

      // console.log(typeof x);
      // console.log(y)

      if(y === "true") {
        this.showBlock = "screen_2"
        this.GETgeneratePlan()
        this.addProgramCustomInputTable = JSON.parse(sessionStorage.getItem("newProgramsList"))
        // this.calculateMinWeeks(false)
        // for(let i = 0; i < this.addProgramCustomInputTable.length; i++) {
        //   for(let j = 3; j < this.addProgramCustomInputTable[i].columns.length; j++) {
        //     this.addProgramCustomInputTable[i].columns[j].name = this.addProgramCustomInputTable[i].columns[j].value 
        //   }
        // }
      } else {
        this.showBlock = "screen_1"
      } 

    }


    // this.calculateWidth();
    // this.getSummarySecondages();
    this.getProgramData();
    this.getAddPlanSummary();
    this.TEMP_handleTopTableBodyData();
    this.TEMP_handleTableCreation();
  },
};
</script>

<style>
.zee-generate-plan-button {
  width: 268px;
  background-color: #6db056;
}

.zee-add-plan-button {
  width: 240px;
  margin-left: 32px !important;
  font-size: 12px;
  background-color: #d63384;
}

.zee-add-program-table {}

.zee-new-program-summary-table {
  display: flex;
  justify-content: flex-start;
  padding: 18px;
}

.zee-table-card-background-1 {
  background-color: #f3f3f3;
  border-radius: 8px;
  padding: 2rem;
}
</style>
