<template>
  <div class="content-center sticky">
    <div class="wrapper" :class="getClass(type)">
      <div class="Group-10 text-center">
        <img
          v-if="type === 'success'"
          src="@/assets/success.svg"
          class="image"
        />
        <img
          v-else-if="type === 'failed'"
          src="@/assets/Icons/failed.png"
          class="image"
        />
        <img
          v-else-if="type === 'edit'"
          src="@/assets/Icons/edit.png"
          class="image"
        />
        <img
          v-else-if="type === 'info'"
          src="@/assets/Icons/not-allowed.png"
          class="image"
        />
        <img
          v-else
          src="@/assets/Icons/delete-bin-6-fill.svg"
          class="error-image"
        />
      </div>
      <div class="message" :class="{ 'text-white': type === 'error' }">
        {{ message }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Toast",
  props: {
    message: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: true,
    },
    time: {
      type: Number,
      default: 200000,
    },
  },
  computed: {
    getClass() {
      return (data) => {
        if (data === "success") {
          return "bg-green";
        } else if (data === "failed") {
          return "bg-red";
        } else if (data === "info") {
          return "bg-yellow";
        } else if (data === "edit") {
          return "bg-yellow";
        } else {
          return "bg-error";
        }
      };
    },
  },
  mounted() {
    setTimeout(() => {
      this.$emit("clearToast");
    }, this.time);
  },
};
</script>
<style scoped>
.sticky {
  position: sticky;
  z-index: 1000;
  top: 0px;
}
.wrapper {
  width: 455px;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-shadow: #8394b4 5px 5px 5px 0px;
}
.bg-green {
  background-color: #55ea76;
}
.bg-red {
  background-color: #ff5a5a;
}
.bg-yellow {
  background-color: #fbbc04;
}
.bg-error {
  background-color: salmon;
}
.image {
  height: 48px;
  width: 48px;
  margin-right: 16px;
}
.error-image {
  height: 32;
  width: 32;
  margin-right: 16px;
}
.message {
  font-family: ProximaNovaBold;
  font-size: 16px;
  color: #222a37;
}
</style>
